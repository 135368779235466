import React from "react"

const HelixIcon = () => (
  <svg className="helix-icon" width="801" height="751" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M517.947 740.524c-109.081 0-249.635-153.258-316.894-348.392-46.995-135.99-48.72-263.345-3.881-331.556 18.54-29.356 46.133-46.193 78.47-48.783 110.374-8.634 256.533 147.646 325.948 347.96 46.995 135.99 48.72 263.345 3.88 331.556-18.97 29.356-46.133 46.193-78.9 48.783-2.587 0-5.605.432-8.623.432zM284.264 13.52c-3.018 0-6.036 0-8.622.431-31.905 2.59-57.774 18.564-77.176 47.489-43.977 67.779-42.253 194.271 4.311 329.828 67.26 194.271 206.521 347.097 314.739 347.097 3.018 0 6.036 0 8.623-.431 31.905-2.591 57.774-18.564 77.175-47.489 44.409-67.779 42.684-193.839-4.311-329.397C532.175 166.346 392.483 13.52 284.264 13.52z" fill="#fc732b" />
    <path d="M540.798 746.569c-103.476 0-245.755-139.444-328.966-327.67-62.086-140.307-77.607-280.182-39.666-355.732 15.09-30.22 37.51-48.783 66.397-55.259 104.338-23.312 262.138 122.606 351.817 325.08 62.086 140.306 77.607 280.181 39.666 355.731-15.09 30.22-37.51 48.784-66.397 55.259-7.329 1.727-15.09 2.591-22.851 2.591zM261.845 7.476c-7.761 0-15.521.864-22.851 2.59-28.456 6.476-50.013 24.608-64.672 53.965-37.941 75.118-22.42 214.129 39.666 354.004 82.78 187.795 224.197 326.375 326.81 326.375 7.761 0 15.522-.863 22.851-2.59 28.456-6.476 50.013-24.608 64.672-53.964 37.941-75.118 22.42-214.13-39.665-354.005C505.444 146.488 364.027 7.476 261.845 7.476z" fill="#fc732b" />
    <path d="M563.218 750.022c-98.733 0-241.012-125.197-337.59-304.358-77.606-144.192-108.218-291.406-77.606-375.59C158.8 38.991 177.34 18.27 202.777 8.34c97.009-38.422 264.726 94.977 374.237 297.882 77.607 144.192 108.219 291.406 77.607 375.59-11.21 30.652-29.749 51.374-55.187 61.303l-.431-.863.431.863c-11.21 4.749-23.282 6.908-36.216 6.908zm-323.793-746c-12.503 0-24.575 2.159-35.785 6.476-24.576 9.93-42.684 30.22-53.894 60.008-30.611 83.752 0 230.535 77.607 373.863 96.146 178.298 237.994 303.062 335.433 303.062 12.504 0 24.576-2.158 35.786-6.475 24.575-9.93 42.683-30.22 53.893-60.008 30.612-83.753 0-230.535-77.606-373.863C478.712 128.787 336.865 4.022 239.425 4.022z" fill="#fc732b" />
    <path d="M584.344 750.885c-94.421 0-235.838-110.95-343.194-278.454-92.697-144.624-138.83-297.882-117.273-390.269 7.761-32.378 22.851-55.259 44.84-68.642 87.954-53.1 263.863 66.052 391.914 265.935 92.697 144.624 138.83 297.882 117.272 390.268-7.329 31.947-22.419 54.828-44.408 68.211-14.228 8.634-30.611 12.951-49.151 12.951zM217.868 3.159c-17.677 0-34.061 3.885-47.858 12.52-21.557 12.95-36.216 35.4-43.546 66.915-21.557 91.955 24.576 244.781 116.842 388.541 127.62 198.588 301.804 317.741 388.896 265.072l.431.863-.431-.863c21.557-12.951 36.216-35.4 43.546-66.915 21.557-91.955-24.576-244.782-116.842-388.542C451.981 113.677 311.427 3.159 217.868 3.159z" fill="#fc732b" />
    <path d="M604.177 749.159c-4.743 0-9.917-.432-15.09-.864-93.56-9.929-222.904-107.928-328.967-249.962C114.392 304.063 59.205 91.228 136.812 23.449 156.213 7.044 181.651.137 213.125 3.591c93.559 9.93 222.904 107.928 328.966 249.961C687.82 447.823 743.007 660.657 664.969 728.005c-15.953 13.814-36.217 21.154-60.792 21.154zM198.035 4.886c-24.144 0-43.977 6.907-59.499 20.29-76.744 66.916-21.557 278.455 123.309 471.431 106.062 141.17 234.545 239.168 327.673 249.098 30.611 3.454 55.618-3.454 74.589-19.427l.862.863-.862-.863c76.744-66.916 21.557-278.455-123.309-471.431C434.735 113.677 306.253 15.679 213.125 5.749c-5.174-.431-10.348-.863-15.09-.863z" fill="#fc732b" />
    <path d="M621.423 744.41c-90.541 0-224.197-86.342-341.039-221.037C119.135 337.305 41.528 119.722 107.925 38.128c16.384-20.29 40.097-30.651 70.708-31.083 90.542-1.295 225.491 85.48 343.195 221.037 161.68 186.068 239.287 403.651 172.89 485.245l-.862-.863.862.863c-16.384 20.291-40.097 30.652-70.708 31.083h-2.587zM180.789 9.635h-2.156c-29.749.432-53.031 10.793-68.983 30.22-65.966 80.299 11.641 296.587 172.89 482.223C399.813 657.204 533.9 743.979 624.01 742.252c29.749-.432 53.031-10.793 68.984-30.22 65.534-80.73-12.073-296.587-172.891-482.223C403.262 95.546 270.468 9.635 180.789 9.635z" fill="#fc732b" />
    <path d="M636.082 737.502c-86.661 0-214.281-72.528-332.847-190.385C127.327 371.841 27.731 152.099 81.625 57.554 94.99 33.81 117.41 19.564 147.59 15.246 233.82 3.59 371.788 77.845 498.977 204.338c175.908 175.707 275.504 395.017 221.61 489.562-13.797 24.176-35.785 38.422-65.966 42.308-6.036.863-12.072 1.295-18.539 1.295zM166.13 16.542c-6.467 0-12.503.431-18.539 1.295-29.319 3.885-50.876 17.7-64.242 41.012-53.03 93.682 46.133 311.697 221.611 486.541 117.703 117.426 244.892 189.953 331.122 189.953 6.467 0 12.503-.431 18.539-1.295 29.319-3.885 50.876-17.7 64.242-41.013 53.031-93.681-46.133-312.128-221.18-486.972C379.549 88.638 252.36 16.542 166.13 16.542z" fill="#fc732b" />
    <path d="M647.292 728.436c-82.781 0-203.071-59.145-319.481-159.302C139.399 406.81 18.677 187.932 58.774 81.299c10.779-28.062 31.474-46.625 61.654-54.396 81.487-20.722 220.317 40.15 353.973 154.985C662.813 344.212 783.535 563.09 743.438 669.723c-10.779 28.061-31.474 46.625-61.654 54.396-10.779 3.022-22.42 4.317-34.492 4.317zM155.351 25.608c-12.072 0-23.713 1.295-34.061 3.885-29.749 7.34-50.013 25.04-60.36 52.67-39.666 105.769 80.624 323.784 268.174 485.244C461.898 681.811 600.297 742.683 680.921 721.96c29.318-7.339 49.582-25.471 59.93-52.669l.862.432-.862-.432c39.666-105.769-80.625-323.352-268.174-485.245C357.129 84.321 237.701 25.608 155.351 25.608z" fill="#fc732b" />
    <path d="M653.328 717.643c-28.887 0-62.086-6.044-98.302-18.132-62.517-20.722-132.363-59.144-201.347-110.086C154.92 442.642 13.934 227.218 39.803 109.36c7.33-32.81 26.3-55.691 57.343-66.915 37.079-13.815 89.248-10.362 150.04 9.929 62.516 20.722 132.362 59.145 201.346 110.087 198.76 147.214 339.745 362.638 313.876 480.064-7.329 32.81-26.3 55.691-57.342 66.916-15.091 5.612-32.337 8.202-51.738 8.202zM148.453 36.4c-18.971 0-35.786 2.59-50.445 8.203-29.749 11.225-48.72 33.242-55.618 65.62-25.869 116.995 114.686 331.124 313.014 477.906 136.674 101.021 273.779 148.078 349.23 119.585 29.75-11.225 48.72-33.242 55.619-65.621 25.869-116.994-114.686-331.123-313.014-477.906C336.865 82.594 226.491 36.4 148.453 36.4z" fill="#fc732b" />
    <path d="M654.19 705.987c-73.295 0-171.166-34.537-273.348-98.43C173.891 478.043 14.366 269.094 25.145 141.307c3.018-37.559 21.126-65.62 52.169-81.162 72.001-35.833 203.933-3.886 344.056 83.752 206.951 129.514 366.476 338.463 355.698 466.25h-1.294 1.294c-3.018 37.559-21.127 65.62-52.169 81.162-19.833 9.929-43.546 14.678-70.709 14.678zM148.022 48.057c-26.731 0-50.444 4.749-69.415 14.246-30.612 15.542-47.858 41.876-50.876 79.435-11.21 126.924 147.884 335.01 354.405 464.092 101.751 63.893 199.19 97.998 272.054 97.998 26.732 0 50.445-4.748 69.415-14.246 30.612-15.542 47.858-41.876 50.876-79.435 10.778-126.924-148.315-335.009-354.404-464.091-101.751-63.894-199.191-98-272.055-98z" fill="#fc732b" />
    <path d="M649.447 693.467c-66.828 0-151.333-24.175-241.012-70.369C196.31 513.443 19.539 313.128 14.797 177.139c-1.294-43.171 14.659-76.413 46.564-96.704 65.965-41.876 193.585-23.312 331.984 48.352 212.556 109.655 389.327 309.97 394.07 445.959 1.293 43.172-14.659 76.414-46.564 96.704-23.282 14.678-54.756 22.017-91.404 22.017zM152.764 60.577c-36.216 0-66.828 7.339-90.11 22.017-31.043 19.427-46.995 52.237-45.702 94.545 4.312 135.558 180.652 334.577 392.777 444.232 137.967 71.233 264.294 89.797 329.828 48.352 31.043-19.859 46.996-52.237 45.271-94.545-4.311-135.558-180.651-334.577-392.776-444.232-88.817-46.194-173.322-70.37-239.288-70.37z" fill="#fc732b" />
    <path d="M637.806 681.379c-57.342 0-125.895-15.541-200.484-45.761-216.005-88.07-407.866-276.296-428.13-419.625-6.899-48.352 6.898-87.638 39.234-113.109 61.223-47.92 179.358-42.74 316.463 12.952 216.006 88.501 407.867 276.728 428.131 419.625 6.898 48.783-6.898 87.637-39.235 113.108-28.024 22.018-68.121 32.81-115.979 32.81zM164.405 72.233c-47.426 0-87.092 10.792-114.685 32.378-31.905 25.04-44.84 63.462-38.373 111.382 20.264 142.465 211.694 329.829 426.838 417.898 135.811 55.691 253.515 60.44 313.876 13.383 31.905-25.039 45.27-63.462 38.372-111.382-20.264-142.465-211.694-329.828-426.837-417.898-73.727-30.22-142.279-45.761-199.191-45.761z" fill="#fc732b" />
    <path d="M618.404 670.587c-45.702 0-97.008-8.203-152.626-25.04C249.772 580.359 44.545 406.378 8.328 257.869c-13.365-53.964-2.586-99.294 30.613-130.809 55.186-52.669 166.423-60.44 297.061-20.722 216.436 65.189 421.663 239.169 457.88 387.678 13.365 54.396 2.587 99.726-30.612 131.241-31.905 29.788-82.78 45.33-144.866 45.33zM183.806 83.457c-61.654 0-111.667 15.11-143.14 44.898-32.768 31.084-43.116 75.55-30.181 129.082C46.27 405.083 251.066 578.2 466.64 643.389c129.776 39.286 240.15 31.515 294.906-20.291 32.767-31.083 43.114-75.55 30.18-129.082-35.785-147.646-240.581-320.763-456.155-385.519-55.187-16.837-106.494-25.04-151.765-25.04z" fill="#fc732b" />
    <path d="M591.674 661.952c-30.181 0-62.948-3.022-97.009-9.497C280.384 611.442 64.379 454.73 12.641 302.767-8.054 242.759-1.156 190.954 32.474 152.531c49.15-56.554 151.764-76.413 275.073-53.1 214.281 41.012 430.286 197.724 482.024 349.687 20.264 59.576 13.365 111.382-19.833 149.804-35.354 41.013-99.164 63.03-178.064 63.03zM210.538 92.092c-78.469 0-141.417 21.585-176.77 62.166C1.43 191.817-5.468 242.759 14.365 301.472c51.737 151.531 267.312 307.811 480.299 348.392 122.446 23.313 224.629 3.886 272.917-52.237 32.768-37.559 39.666-88.933 19.402-147.646-51.738-151.531-267.312-307.811-480.3-348.392-33.629-6.475-65.965-9.498-96.146-9.498z" fill="#fc732b" />
    <path d="M560.199 656.772c-12.072 0-24.575-.432-37.509-1.295C313.582 639.935 88.523 502.65 21.264 348.529c-27.594-63.462-25.007-123.038 7.33-168.8 43.545-61.735 134.949-91.955 250.928-83.32 209.107 15.11 434.166 152.826 501.426 306.947 27.593 63.462 25.006 123.47-6.899 168.8-38.803 54.396-115.548 84.616-213.85 84.616zm-318.187-559.5c-97.44 0-173.322 29.788-211.694 83.752-32.336 45.33-34.492 103.18-6.899 166.641C90.248 500.923 314.445 637.776 522.69 653.318c115.116 8.634 206.089-21.586 249.203-82.457 32.337-45.33 34.492-103.179 6.899-166.641-66.828-153.258-291.026-290.111-499.27-305.653-12.935-.863-25.438-1.295-37.51-1.295z" fill="#fc732b" />
    <path d="M523.121 655.908c-194.879 0-409.16-112.677-487.629-259.459C-.293 329.533-3.31 262.618 26.87 208.654c36.648-65.62 118.997-106.201 225.491-112.245 201.346-10.793 432.011 105.337 514.36 259.027 35.786 66.916 38.804 133.831 8.623 187.795-36.647 65.621-118.997 106.202-225.491 112.246-8.623 0-17.677.431-26.731.431zM279.091 98.135c-9.054 0-17.677.432-26.731.864-105.632 5.612-187.119 46.193-223.335 110.95-29.75 53.101-26.731 119.153 8.623 185.205C115.686 541.504 329.104 653.75 523.12 653.75c9.054 0 17.677-.432 26.731-.864 105.632-5.612 187.119-46.193 223.767-110.95 29.749-53.1 26.731-119.153-8.623-185.205-78.469-146.35-291.888-258.596-485.905-258.596z" fill="#fc732b" />
    <path d="M486.905 659.794c-169.442 0-350.524-86.343-432.442-214.993C10.486 375.295 1 302.335 27.73 239.737c29.75-69.506 102.183-120.448 198.76-139.443 190.999-37.56 424.681 54.827 521.259 206.79 43.977 69.506 53.893 142.466 26.731 205.064-29.749 69.506-102.182 120.448-198.76 139.443-28.887 5.612-58.636 8.203-88.816 8.203zM315.308 94.25c-30.181 0-59.93 2.59-88.386 8.202-95.715 18.996-167.717 69.074-197.035 138.149-26.731 62.166-17.246 134.262 26.731 202.905C152.765 594.605 385.154 686.56 575.29 649.001c95.715-18.996 167.717-69.074 197.035-138.148 26.731-62.167 17.246-134.263-26.731-202.905C664.107 180.161 484.318 94.25 315.308 94.25z" fill="#fc732b" />
    <path d="M454.568 668.428c-144.435 0-294.474-65.62-375.961-175.275-52.17-70.37-68.984-148.51-47.427-220.174 22.42-74.686 83.212-133.831 171.166-165.778 178.065-64.757 411.747 3.022 521.69 150.668 52.169 70.369 68.984 148.509 47.426 220.174-22.419 74.686-83.211 133.831-171.166 165.777-46.132 16.837-95.715 24.608-145.728 24.608zM347.643 85.184c-50.013 0-99.164 7.77-144.866 24.608-87.523 31.946-147.884 90.228-169.872 164.05-21.126 71.233-4.312 148.51 47.426 218.015 81.056 109.224 230.234 173.981 374.237 173.981 50.013 0 99.164-7.771 144.866-24.608 87.523-31.947 147.884-90.228 169.873-164.051 21.126-71.232 4.311-148.509-47.427-218.015-81.056-108.791-230.233-173.98-374.237-173.98z" fill="#fc732b" />
    <path d="M428.269 681.811c-120.722 0-243.168-49.215-321.206-139.875-59.499-69.074-84.505-151.099-70.277-230.966 13.796-79.867 64.672-148.078 143.141-192.112 162.112-91.523 393.639-50.51 515.654 91.091 59.498 69.074 84.505 151.1 70.277 230.966-13.797 79.867-64.672 148.078-143.141 192.113-58.637 33.241-126.327 48.783-194.448 48.783zM373.944 72.233c-67.69 0-134.95 15.541-193.155 48.352C102.751 164.619 52.307 231.966 38.51 310.97c-13.796 79.435 10.779 161.029 69.846 229.239 121.584 140.739 351.386 181.751 512.636 90.66 78.038-44.035 128.482-111.382 142.279-190.385 13.797-79.435-10.779-161.029-69.846-229.24-77.176-90.228-199.191-139.011-319.481-139.011z" fill="#fc732b" />
    <path d="M410.16 699.08c-98.733 0-198.328-37.128-270.33-109.655-65.535-65.621-98.733-149.805-94.421-236.579 4.311-85.479 44.839-163.187 114.254-219.742C303.667 15.678 529.588 28.63 662.813 162.461c65.104 65.62 98.733 149.372 93.991 236.578-4.312 85.479-44.84 163.188-114.255 219.742-65.965 53.532-149.177 80.299-232.389 80.299zM392.052 54.964c-82.781 0-165.561 26.335-231.096 79.867C92.404 190.954 52.306 268.23 47.564 352.846c-4.311 86.343 28.887 169.663 93.56 234.852 132.362 132.967 356.559 145.919 500.132 29.356 68.553-56.122 109.081-133.399 113.392-218.015 4.311-86.342-28.887-169.663-93.559-234.851C589.087 91.66 490.354 54.964 392.052 54.964z" fill="#fc732b" />
    <path d="M400.675 718.938c-79.763 0-159.525-27.629-224.629-83.752-142.279-123.902-157.8-341.485-34.06-484.382C265.725 7.907 482.593-7.634 625.734 116.699c142.71 123.902 158.232 341.053 34.061 484.382-67.259 77.708-162.974 117.857-259.12 117.857zm.431-683.833c-95.284 0-190.137 39.718-257.396 117.426C20.833 294.565 35.923 509.989 177.771 633.459c64.241 55.691 144.004 83.321 222.904 83.321 95.284 0 190.136-39.718 257.395-117.426 122.878-142.034 107.788-357.89-34.06-480.928-64.241-56.123-143.573-83.32-222.904-83.32z" fill="#fc732b" />
  </svg>
)

export default HelixIcon
