import React from "react"
import { Fade } from "react-awesome-reveal";

const Hero = ({ heroContent }) => {
  return (
    <section className="hero">
      <div className="container">
        <Fade triggerOnce>
          <p>{heroContent.small_title}</p>
          <h2>{heroContent.title}</h2>
          {heroContent.caption &&
            <p>{heroContent.caption}</p>
          }
        </Fade>
      </div>
    </section>
  )
}

export default Hero
