import React from "react"

const WarpIcon = () => (
  <svg className="warp-icon" width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M434.744 560.802c-44.098 0-90.869-12.918-132.294-39.644-50.334-32.517-84.187-79.287-91.76-128.73-5.79-36.526 2.673-70.379 24.945-97.996 53.897-66.815 171.047-73.497 261.915-15.145 50.334 32.517 84.187 79.288 91.76 128.731 5.79 36.525-2.673 70.378-24.945 97.995-28.953 36.081-77.06 54.789-129.621 54.789zM365.256 240.98c-51.67 0-99.332 18.263-128.285 54.343-21.826 27.171-30.735 61.024-24.499 97.105 8.018 48.997 40.98 95.323 91.314 127.839 89.978 57.907 206.682 51.225 259.688-14.699 21.827-27.172 30.735-61.025 24.499-97.105-8.018-48.998-40.98-95.323-91.314-127.839-40.98-26.726-87.75-39.644-131.403-39.644z" fill="#fc732b" />
    <path d="M420.49 568.82c-30.735 0-62.361-6.236-93.096-19.599-53.897-23.608-95.323-65.034-113.14-114.032-14.254-38.752-12.918-79.287 4.454-114.476 40.089-82.406 154.12-114.031 254.343-70.379 53.898 23.608 95.323 65.034 113.14 114.031 14.254 38.753 12.918 79.288-4.454 114.477-28.062 57.461-91.314 89.978-161.247 89.978zM219.154 321.604l.89.445c-16.926 34.744-18.262 74.833-4.008 113.14 17.817 48.553 58.797 89.978 112.249 113.141 99.332 43.207 212.472 12.026 252.116-69.488 16.926-34.744 18.263-74.833 4.009-113.14-17.818-48.553-58.798-89.978-112.25-113.141-99.332-43.207-212.472-12.026-252.116 69.488l-.89-.445z" fill="#fc732b" />
    <path d="M408.463 580.401c-16.926 0-33.853-1.782-51.225-6.236-54.343-12.918-101.559-46.325-129.176-91.76-24.053-39.643-31.626-85.523-20.935-128.73h.891-.891c23.608-96.214 129.621-153.23 236.08-127.84 54.788 12.918 102.004 46.771 129.621 92.205 24.054 39.644 31.626 85.523 20.936 128.731-20.045 80.623-97.996 133.63-185.301 133.63zm-16.926-359.02c-86.86 0-163.92 53.007-183.519 132.739-10.691 42.762-3.118 88.196 20.935 127.394 27.617 44.989 74.388 78.397 128.285 91.315 105.568 25.389 210.691-31.181 234.299-126.504 10.69-42.762 3.118-88.196-20.936-127.394-27.171-45.434-73.942-78.397-128.285-91.76-16.926-4.008-33.853-5.79-50.779-5.79z" fill="#fc732b" />
    <path d="M401.336 595.1c-3.118 0-6.681 0-9.799-.445C281.96 589.31 196.882 497.55 201.782 390.2c2.227-51.225 24.499-98.886 62.806-133.63 39.198-36.08 90.869-54.343 144.321-51.67 109.576 5.345 194.654 97.104 189.755 204.454-2.228 51.225-24.499 98.887-62.807 133.63-36.971 33.853-84.187 52.116-134.521 52.116zm-2.227-388.418c-49.889 0-96.659 18.262-133.63 51.67-37.862 34.744-60.134 81.514-62.361 132.294-4.9 106.459 79.733 197.327 188.419 202.672 53.452 2.673 104.231-15.59 143.429-51.225 37.862-34.743 60.134-81.514 62.361-132.293 4.9-106.459-79.732-197.328-188.418-202.673-3.564-.445-6.682-.445-9.8-.445z" fill="#fc732b" />
    <path d="M399.109 612.027c-40.534 0-79.733-12.918-113.586-37.862-45.434-33.853-74.833-84.633-82.85-142.985-15.591-115.367 59.688-223.608 168.374-240.534 50.334-8.018 101.113 4.9 142.984 35.635 45.434 33.853 74.833 84.632 82.851 142.984 15.59 115.367-59.688 223.608-168.374 240.535-9.8 1.336-19.599 2.227-29.399 2.227zm1.782-422.272c-9.8 0-19.599.891-28.953 2.227C264.143 209.354 189.31 316.258 204.9 430.735c8.018 57.906 36.971 108.24 81.96 141.648 41.425 30.735 91.759 43.207 141.648 35.635 107.795-16.927 182.628-124.276 167.038-238.753-8.018-57.906-36.971-108.241-81.96-141.648-33.408-24.944-72.606-37.862-112.695-37.862z" fill="#fc732b" />
    <path d="M404.454 629.844c-26.28 0-52.561-5.791-77.06-17.817-53.006-25.39-94.877-75.724-114.476-138.085-37.862-119.822 15.59-250.779 118.931-291.314 45.879-18.263 95.768-16.036 141.202 5.345 53.007 25.39 94.878 75.724 114.477 138.085 37.416 119.822-16.036 250.779-119.377 291.759-20.49 8.018-42.316 12.027-63.697 12.027zm-8.463-457.906c-21.381 0-42.762 4.009-63.252 12.026-102.449 40.535-155.456 170.156-118.04 289.533 19.154 61.915 60.579 111.804 113.586 137.193 44.989 21.381 94.432 23.163 139.421 5.346 102.45-40.535 155.457-170.156 118.04-289.533-19.154-61.915-60.579-111.804-113.586-137.193-24.499-11.582-50.334-17.372-76.169-17.372z" fill="#fc732b" />
    <path d="M414.699 648.107c-13.808 0-28.062-1.782-41.871-5.345-58.797-15.145-110.913-60.579-142.539-124.722-59.688-120.267-32.071-270.824 61.47-335.857 40.089-28.063 88.196-36.526 135.412-24.499 58.798 15.145 110.913 60.579 142.539 124.721 59.688 120.268 32.072 270.824-61.47 335.858-28.062 19.599-60.579 29.844-93.541 29.844zM232.071 517.149c31.626 63.697 83.296 108.686 141.648 123.831 46.771 12.027 94.432 3.563 134.076-24.054 92.65-64.588 120.267-213.808 61.024-333.63-31.625-63.697-83.296-108.686-141.648-123.831-46.77-12.026-94.432-3.563-134.075 24.054-93.096 64.588-120.713 213.808-61.025 333.63z" fill="#fc732b" />
    <path d="M429.844 665.033c-3.118 0-6.236 0-9.354-.445-61.025-3.564-121.158-41.425-164.811-103.786-81.514-115.813-83.296-282.406-3.563-371.047 33.853-37.862 79.287-57.016 127.394-54.343 61.024 3.564 121.158 41.425 164.811 103.786 81.514 115.813 83.296 282.406 3.563 371.047-31.18 35.635-73.051 54.788-118.04 54.788zm-59.688-527.839c-44.098 0-85.524 19.153-116.704 53.897-78.842 88.196-77.06 253.452 4.009 368.374 43.653 61.916 102.895 99.332 163.474 102.896 47.662 2.672 92.651-16.036 126.058-53.452 78.842-88.196 77.06-253.452-4.009-368.374-43.652-61.916-102.895-99.332-163.474-102.896-3.118-.445-6.236-.445-9.354-.445z" fill="#fc732b" />
    <path d="M448.552 679.733c-54.343 0-111.358-27.617-159.465-77.951-102.005-106.904-135.412-283.742-74.388-395.1 26.281-48.107 68.152-78.397 117.149-84.633 60.134-8.018 125.613 20.045 179.51 76.615 102.005 106.904 135.412 283.741 74.388 395.1-26.281 48.107-68.151 78.396-117.149 84.632a151.489 151.489 0 01-20.045 1.337zm-96.659-557.239c-6.682 0-13.363.446-20.045 1.337-48.106 6.236-89.086 36.08-115.367 83.741C155.902 318.04 188.864 493.987 290.423 600c53.898 56.125 118.486 83.742 177.728 76.169 48.107-6.236 89.087-36.08 115.368-83.741C644.098 481.96 611.136 306.013 509.577 200c-47.662-49.889-104.232-77.506-157.684-77.506z" fill="#fc732b" />
    <path d="M469.042 691.314c-44.988 0-93.986-18.263-139.42-53.452-120.713-92.65-187.083-274.833-148.33-405.791 16.926-58.797 53.897-100.222 102.895-116.258 56.57-18.263 124.276-1.336 186.637 46.325 120.713 92.65 187.083 274.833 148.33 405.791-17.372 58.797-53.898 100.222-103.341 116.258-14.699 4.9-30.735 7.127-46.771 7.127zM331.403 110.913c-16.035 0-31.626 2.227-46.325 7.127-48.552 15.59-84.632 56.57-102.004 114.922-38.308 130.512 27.617 311.359 147.884 403.564 61.47 47.216 128.73 64.142 184.41 45.879 48.552-15.59 84.632-56.57 102.004-114.922 38.307-130.512-27.617-311.358-147.884-403.563-45.434-34.744-93.541-53.007-138.085-53.007z" fill="#fc732b" />
    <path d="M488.641 700.223c-35.189 0-73.942-10.691-112.694-32.072-135.858-73.942-236.081-254.343-223.163-402.227 6.236-69.933 36.526-122.494 85.078-148.775 51.67-28.062 118.04-22.717 185.746 14.254 135.857 73.942 236.08 254.343 223.163 402.227-6.237 69.933-36.526 122.495-85.078 148.775-21.827 12.027-46.326 17.818-73.052 17.818zM311.359 102.004c-25.836 0-50.335 5.791-72.161 17.372-48.107 25.836-77.951 77.951-84.187 146.994-12.917 146.993 86.86 326.503 221.826 400 67.261 36.525 132.74 41.425 183.965 14.254 48.107-25.836 77.951-77.951 84.187-146.994 12.917-146.993-86.86-326.503-221.826-400-38.753-20.935-76.615-31.626-111.804-31.626z" fill="#fc732b" />
    <path d="M507.35 705.568c-24.945 0-51.671-4.9-79.733-14.254-147.439-50.78-280.178-222.272-295.768-382.628-7.573-79.733 14.254-144.321 61.47-181.737 45.434-35.635 108.686-42.317 179.064-18.263 147.439 50.779 280.178 222.272 295.769 382.628 7.572 79.733-14.254 144.321-61.47 181.737-27.172 21.827-61.47 32.517-99.332 32.517zM293.096 96.659c-37.417 0-70.824 10.691-97.996 32.072-46.77 36.971-68.597 100.668-61.024 179.51 15.59 159.465 147.439 330.512 294.432 380.846 69.488 23.608 132.294 17.372 176.837-17.818 46.771-36.971 68.597-100.668 61.025-179.51-15.59-159.465-147.439-330.512-294.432-380.846-27.617-9.354-54.343-14.254-78.842-14.254z" fill="#fc732b" />
    <path d="M520.713 708.24c-12.472 0-24.945-.89-37.862-3.118-154.566-23.162-317.595-179.064-363.475-346.993-23.608-86.414-12.026-164.365 31.626-213.363 37.862-42.762 97.105-60.133 166.593-49.888C472.16 118.04 635.189 273.942 681.069 441.87c23.608 86.414 12.027 164.365-31.626 213.363-30.735 34.744-75.724 53.006-128.73 53.006zM279.287 94.432c-52.115 0-96.213 17.817-126.948 52.116-43.208 48.552-54.343 125.167-31.181 211.136 45.434 167.038 207.573 322.049 361.247 345.211 68.597 10.245 126.949-7.127 164.366-48.997 43.207-48.553 54.343-125.168 31.18-211.136C632.517 275.724 470.379 120.713 316.704 97.55c-12.472-2.227-24.944-3.118-37.417-3.118z" fill="#fc732b" />
    <path d="M527.394 708.686c-153.675 0-335.857-129.621-410.69-295.323-41.426-91.314-43.653-179.51-5.791-240.98 29.844-48.998 83.296-77.506 150.111-80.624 156.348-7.127 346.103 125.168 423.163 294.878 41.425 91.314 43.652 179.51 5.791 240.98-29.845 48.998-83.297 77.505-150.112 80.624-4.454.445-8.463.445-12.472.445zm12.027-2.673v1.337-1.337c65.924-3.118 118.485-31.18 147.884-79.287 37.416-61.024 35.189-148.33-5.791-238.753C604.9 219.154 416.036 87.305 261.024 94.433c-65.924 2.672-118.485 31.179-147.884 79.286-37.416 61.025-35.19 148.33 5.791 238.753 76.615 168.82 265.033 300.668 420.49 293.541z" fill="#fc732b" />
    <path d="M525.167 708.686c-141.648 0-309.131-94.878-401.336-235.635-59.688-91.759-77.506-187.528-48.998-262.361 21.381-55.679 66.37-93.986 129.622-110.913 151.893-40.089 363.474 61.916 471.714 227.172 60.134 91.759 77.951 187.528 49.444 262.361-21.381 55.679-66.37 93.986-129.622 110.913-22.717 5.79-46.325 8.463-70.824 8.463zm-250.334-614.7c-24.053 0-47.661 2.673-69.933 8.464-62.361 16.481-106.459 54.343-127.84 109.131-28.508 73.942-10.69 168.82 48.998 260.134C233.408 636.08 443.653 737.194 594.655 697.55c62.361-16.481 106.459-54.343 127.84-109.131 28.507-73.942 10.69-168.82-48.998-260.134C582.183 188.419 415.59 93.987 274.833 93.987z" fill="#fc732b" />
    <path d="M513.586 710.468c-126.503 0-271.715-64.588-370.602-175.056-77.505-87.305-113.14-187.082-97.55-273.497 11.136-62.36 47.662-110.913 105.568-141.202 141.648-74.388 368.82-9.354 506.459 144.32 77.506 86.86 113.14 186.637 97.55 273.497-11.136 62.361-47.661 110.913-105.568 141.203l-.445-1.337.445 1.337c-39.643 20.935-86.414 30.735-135.857 30.735zM286.86 92.65c-48.998 0-95.323 9.8-134.967 30.735-57.016 29.844-92.65 77.951-103.786 139.421-15.59 85.969 19.6 184.855 97.105 271.27 98.441 110.022 242.761 174.165 368.374 174.165 48.998 0 95.323-9.8 134.966-30.735 57.016-29.845 92.651-77.951 103.787-139.421 15.59-85.969-19.599-184.856-97.105-271.27C556.793 156.793 412.472 92.65 286.86 92.65z" fill="#fc732b" />
    <path d="M491.314 715.367c-106.459 0-224.053-40.534-318.04-118.04C80.624 520.713 26.726 422.272 25.39 326.503c-.891-68.597 25.39-128.285 76.614-172.828 125.167-108.686 360.357-86.86 524.722 48.998 92.65 76.614 146.993 175.501 147.884 271.269.891 68.597-25.39 128.285-76.615 172.829-53.006 46.325-126.948 68.596-206.681 68.596zM308.686 87.305c-78.842 0-151.893 22.272-204.9 68.597-50.334 43.653-76.17 102.45-75.724 170.601 1.337 94.878 54.789 192.873 146.994 269.043 163.474 134.966 397.327 156.792 521.158 48.997l.891.891-.891-.891c50.334-43.652 76.169-102.895 75.278-170.601-.891-94.877-54.343-192.873-146.548-269.042-93.541-77.06-210.245-117.595-316.258-117.595z" fill="#fc732b" />
    <path d="M462.806 726.503c-81.96 0-169.265-22.717-247.661-69.042C111.804 596.437 40.089 504.232 19.154 404.454c-15.59-73.942-.891-146.993 40.98-205.345 102.004-142.093 337.639-167.038 524.721-56.57 103.787 61.024 175.056 153.675 195.991 253.007 15.591 73.942.891 146.993-40.979 205.345L738.53 600l1.337.891c-59.243 82.405-163.475 125.612-277.061 125.612zM337.194 76.615c-112.695 0-216.036 42.761-274.388 124.276-42.316 58.352-56.124 128.73-40.534 203.118 20.935 98.886 91.759 190.646 194.655 251.225 77.951 46.325 164.81 68.597 246.325 68.597 112.695 0 216.035-42.762 274.387-124.276 42.317-58.798 56.125-128.731 40.535-203.118-20.936-98.887-91.76-190.646-194.655-251.225-78.396-46.326-164.811-68.597-246.325-68.597z" fill="#fc732b" />
    <path d="M434.744 744.766c-54.789 0-111.804-10.245-167.038-31.626C158.575 670.824 72.16 590.646 31.18 493.541c-33.407-78.842-34.298-163.029-3.118-237.416C101.114 83.742 327.394 7.572 532.294 86.86 641.425 129.176 727.84 209.354 768.82 306.459c33.407 78.842 34.298 163.029 3.118 237.416-53.007 126.058-188.419 200.891-337.194 200.891zM365.702 58.797c-147.885 0-281.96 73.942-334.522 198.664C0 330.958.89 414.254 33.853 492.205c40.98 96.659 126.503 175.946 235.189 217.817 203.564 78.842 428.063 3.564 500.223-167.483 31.18-73.497 30.29-156.793-3.118-234.744-40.98-96.659-126.503-175.946-235.189-217.817-54.343-21.381-110.913-31.18-165.256-31.18z" fill="#fc732b" />
    <path d="M412.472 769.71c-27.171 0-54.788-2.672-82.85-8.017-104.678-20.49-195.992-77.06-257.016-159.911-60.58-82.851-83.742-181.292-65.033-277.951C46.325 124.722 253.898-3.563 470.379 38.753 686.86 80.623 831.626 277.06 792.873 476.615l-1.782-.446 1.782.446C759.02 649.889 596.882 769.71 412.472 769.71zM387.528 33.853c-182.628 0-343.43 118.486-376.838 290.423-18.708 95.769 4.455 193.319 65.034 275.279 60.579 81.959 151.002 138.084 255.234 158.574 27.617 5.345 55.234 8.018 81.96 8.018 183.073 0 343.43-118.486 376.837-290.423C828.062 278.396 684.633 83.296 469.933 41.871c-27.617-5.345-55.234-8.018-82.405-8.018z" fill="#fc732b" />
    <path d="M400 800C179.51 800 0 620.935 0 400S179.51 0 400 0s400 179.51 400 400-179.065 400-400 400zm0-796.437C181.292 3.563 3.118 181.737 3.118 400.445c0 218.709 178.174 396.882 396.882 396.882s396.882-178.173 396.882-396.882C796.882 181.737 618.708 3.563 400 3.563z" fill="#fc732b" />
  </svg>
)

export default WarpIcon
